import * as React from "react";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import "./Table.css";

const makeStyle = (status) => {
  if (status === "Active") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Inactive") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else if (status === "Banned") {
    return {
      background: "#ff4d4d",
      color: "white",
    };
  } else if (status === "Pending Verification") {
    return {
      background: "#ffd7008f",
      color: "#ff8c00",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function UserTable() {
  return <></>
  
}
