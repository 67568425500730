import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const PlanActivateDeactivate = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [phone, setPhone] = useState('');
  const [userData, setUserData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    fetchPlans();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/super-admin/all-users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_token')}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchPlans = async () => {
    try {
      const token = localStorage.getItem('user_token');
      const response = await axios.get(`${API_URL}/super-admin/plans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPlans(response.data.plans || []);
    } catch (error) {
      console.error('Error fetching plans:', error);
      setPlans([]);
    }
  };

  const handleSearch = async () => {
    let response;
    try {
      if (email) {
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-email`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData([response.data]);
      } else if (phone) {
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-phone`,
          { phone },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData([response.data]);
      } else if (name) {
        const [firstName, lastName] = name.split(' ');
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-name`,
          { firstName, lastName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData(response.data);
      } else if (userId) {
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-id`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData([response.data]);
      }
    } catch (error) {
      console.error('Error searching user:', error);
    }
  };

  const handleReset = () => {
    setEmail('');
    setName('');
    setUserId('');
    setPhone('');
    fetchData();
  };

  const handleSelectPlan = (userId, planId) => {
    setUserData((prevUserData) =>
      prevUserData.map((user) =>
        user.userId === userId ? { ...user, selectedPlanId: planId } : user
      )
    );
  };

  const handleActivateForUser = async (userId, selectedPlanId) => {
    if (!userId || !selectedPlanId) return;
    setLoading(true);
    try {
      const token = localStorage.getItem('user_token');
      await axios.post(
        `${API_URL}/super-admin/user/${userId}/plan/${selectedPlanId}/activate`, // Use the selectedPlanId here
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Plan activated successfully!');
    } catch (error) {
      console.error('Error activating plan:', error);
      alert('Failed to activate plan.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeactivateForUser = async (userId, selectedPlanId) => {
    if (!userId || !selectedPlanId) return;
    setLoading(true);
    try {
      const token = localStorage.getItem('user_token');
      await axios.post(
        `${API_URL}/super-admin/user/${userId}/plan/deactivate`,
        { planId: selectedPlanId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Plan deactivated successfully!');
    } catch (error) {
      console.error('Error deactivating plan:', error);
      alert('Failed to deactivate plan.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="MainDash">
      <h1>Activate/Deactivate Plans</h1>
      <div className="select-bar-container">
        <div className="select-bar">
          <input
            type="text"
            placeholder="Search By Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <input
            type="text"
            placeholder="Search By Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <input
            type="text"
            placeholder="Search By User ID"
            value={userId}
            onChange={(e) => setUserId(e.currentTarget.value)}
          />
          <input
            type="text"
            placeholder="Search By Phone"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />
          <div className="button-group">
            <button onClick={handleSearch} className="search-button">
              Search
            </button>
            <button onClick={handleReset} className="reset-button">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="Table">
        <h3>User List</h3>
        <div className="table-container">
          <TableContainer
            component={Paper}
            style={{
              boxShadow: '0px 13px 20px 0px #80808029',
              overflowY: 'auto',
              height: 400,
            }}
          >
            <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: '#1F618D' }}>
                <TableRow>
                  <TableCell align="left">Profile Picture</TableCell>
                  <TableCell align="left">User ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Select Plan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((user) => (
                  <TableRow key={user.userId}>
                    <TableCell align="left">
                      <img
                        src={user.photos?.[0]?.url || '/path/to/defaultProfilePictureUrl.jpg'}
                        alt="Profile"
                        style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                      />
                    </TableCell>
                    <TableCell align="left">{user.userId}</TableCell>
                    <TableCell align="left">{`${user.firstName} ${user.lastName}`}</TableCell>
                    <TableCell align="left">{user.phone}</TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="left">
                      <select
                        onChange={(e) => handleSelectPlan(user.userId, e.target.value)}
                        value={user.selectedPlanId || ''}
                      >
                        <option value="" disabled>
                          Select Plan
                        </option>
                        {plans.length === 0 && <option disabled>No Plans Available</option>}
                        {plans.map((plan, index) => (
                          <option key={index} value={plan._id}>
                            {plan.name}
                          </option>
                        ))}
                      </select>
                      <div className="button-group">
                        <button
                          onClick={() => handleActivateForUser(user.userId, user.selectedPlanId)}
                          disabled={!user.selectedPlanId || loading}
                          className="activate-button"
                        >
                          Activate Plan
                        </button>
                        <button
                          onClick={() => handleDeactivateForUser(user.userId, user.selectedPlanId)}
                          disabled={!user.selectedPlanId || loading}
                          className="deactivate-button"
                        >
                          Deactivate Plan
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default PlanActivateDeactivate;
