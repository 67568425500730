import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Chatlist.css'; // Make sure to create and import a CSS file for styling

const ChatList = () => {
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showChatBox, setShowChatBox] = useState(false);
  const [userData, setUserData] = useState([]);

  const token = localStorage.getItem('user_token');
  const apiBaseUrl = 'https://admin.hisathi.com';

  // Function to fetch user details based on various criteria
  const fetchUserDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      let response;
      if (email) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-email`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (phone) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-phone`,
          { phone },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (name) {
        const [firstName, lastName] = name.split(" ");
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-name`,
          { firstName, lastName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (userId) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-id`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      console.log("email tets")

      if (response && response.data) {
        // console.log(response)
        if (Array.isArray(response.data)){
          setUserDetails(response.data[0]);

        }else{
          setUserDetails(response.data);
        }
        const chats = await fetchChatList(Array.isArray(response.data)?response.data[0]._id:response.data._id );
        setChatList(chats);
      } else {
        setError('User not found');
        setChatList([]);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message);
      } else {
        setError(err.message);
      }
      setChatList([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch chat list from the API
  const fetchChatList = async (userId) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/super-admin/user/${userId}/chats`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.conversations;
    } catch (err) {
      throw new Error('Error fetching chat list');
    }
  };

  // Fetch chat history from the API
  const fetchChatHistory = async (userId, targetId) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/super-admin/user/${userId}/chat-history/${targetId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    } catch (err) {
      throw new Error('Error fetching chat history');
    }
  };

  // Handle search button click
  const handleSearch = async () => {
    await fetchUserDetails();
  };

  // Handle chat icon click to view chat history
  const handleChatIconClick = async (targetId) => {
    setLoading(true);
    setError(null);
    setSelectedChat(targetId);
    setShowChatBox(true);
    try {
      const history = await fetchChatHistory(userDetails._id, targetId);
      setChatHistory(history);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Close the chat box
  const handleCloseChatBox = () => {
    setShowChatBox(false);
    setChatHistory([]);
    setSelectedChat(null);
  };

  // Initial fetch for user data (optional)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/super-admin/all-users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div className="chat-container">
      <h2>Chat List</h2>
      <div className="search-container">
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Enter User ID"
        />
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter Email"
        />
        <input
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Enter Phone"
        />
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter Name"
        />
        <button onClick={handleSearch} disabled={loading}>
          Search
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      <div className="user-details-container">
        {userDetails && (
          <div>
            <h3>User Details</h3>
            <p>Email: {userDetails.email}</p>
            <p>Phone: {userDetails.phone}</p>
            <p>Status: {userDetails.status}</p>
          </div>
        )}
      </div>
      <div className="table-container">
        {chatList.length > 0 ? (
          <table className="chat-table">
            <thead>
              <tr>
                <th>Profile Photo</th>
                <th>Chats</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {chatList.map((chat, index) => (
                <tr key={index}>
                  <td>
                    {chat.firstPhoto ? (
                      <img
                        src={chat.firstPhoto.url}
                        alt="Profile"
                        width="50"
                        height="50"
                      />
                    ) : (
                      'No Photo'
                    )}
                  </td>
                  <td>
                    <div>{chat.firstName}</div>
                    <div>{chat.lastMessage.message}</div>
                  </td>
                  <td>
                    <button onClick={() => handleChatIconClick(chat.conversation_id)}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          !loading && <p>No chats found.</p>
        )}
      </div>
      {showChatBox && (
        <div className="chat-history-box">
          <div className="chat-header">
            <h3>Chat History</h3>
            <button className="close-chat-box" onClick={handleCloseChatBox}>
              Close
            </button>
          </div>
          {chatHistory.length > 0 ? (
            <div className="chat-history">
              {chatHistory.map((message, index) => (
                <div
                  key={index}
                  className={
                    message.sender === userDetails._id ? 'message sender' : 'message receiver'
                  }
                >
                  <p>{message.message}</p>
                  <small>{new Date(message.createdAt).toLocaleString()}</small>
                </div>
              ))}
            </div>
          ) : (
            <p>No chat history found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatList;
