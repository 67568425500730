import React from "react";

import Updates from "../Updates/Updates";
import "./RightSide.css";

const RightSide = () => {
  
};

export default RightSide;
