import React, { useState,useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ViewMatches.css';

const apiBaseUrl = 'https://admin.hisathi.com';

const ViewMatches = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [requestType, setRequestType] = useState('sent');
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState([]);
  const token = localStorage.getItem('user_token');
  
  const fetchAllData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/super-admin/all-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchUserId = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiBaseUrl}/super-admin/all-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const user = response.data.find(user =>
        user.userId === searchQuery || 
        `${user.firstName} ${user.lastName}` === searchName || 
        user.email === searchEmail
      );

      if (!user) {
        setError('User not found');
        setLoading(false);
        return null;
      }

      return user.userId;
    } catch (error) {
      if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
        setError('Network error. Please check your internet connection.');
      } else if (error.message.includes('net::ERR_NAME_NOT_RESOLVED')) {
        setError('DNS resolution error. Please check the URL or DNS settings.');
      } else {
        setError('Error fetching user data.');
      }
      setLoading(false);
      return null;
    }
  };

  const fetchRequests = async (userId) => {
    setLoading(true);
    setError(null);
    try {
      let response;
      const encodedUserId = encodeURIComponent(userId);

      if (requestType === 'sent') {
        response = await axios.get(`${apiBaseUrl}/api/matching/sent-requests/${encodedUserId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else if (requestType === 'declined') {
        response = await axios.get(`${apiBaseUrl}/api/matching/declined-requests/${encodedUserId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else if (requestType === 'received') {
        response = await axios.get(`${apiBaseUrl}/api/matching/receive-requests/${encodedUserId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else if (requestType === 'accepted') {
        response = await axios.post(`${apiBaseUrl}/api/matching/accepted-requests/${encodedUserId}`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }

      const data = requestType === 'received' ? response.data.receivedRequests : response.data;
      const requestData = Array.isArray(data) ? data : [];
      setRequests(requestData);
    } catch (error) {
      if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
        setError('Network error. Please check your internet connection.');
      } else if (error.message.includes('net::ERR_NAME_NOT_RESOLVED')) {
        setError('DNS resolution error. Please check the URL or DNS settings.');
      } else {
        setError('Error fetching requests.');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    const id = await fetchUserId();
    if (id) {
      fetchRequests(id);
    }
  };

  const handleRequestTypeChange = (event) => {
    setRequestType(event.target.value);
  };

  return (
    <div className="container my-4">
      <h2 className="text-center">Matches</h2>
      <div className="row mb-3">
        <div className="col-md-10 offset-md-1">
          <div className="row g-3 align-items-center">
            <div className="col-md-3">
              <label htmlFor="searchQuery" className="form-label">Search by User ID:</label>
              <input
                type="text"
                id="searchQuery"
                className="form-control"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="User ID"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="searchName" className="form-label">Search by Name:</label>
              <input
                type="text"
                id="searchName"
                className="form-control"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                placeholder="Name"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="searchEmail" className="form-label">Search by Email:</label>
              <input
                type="text"
                id="searchEmail"
                className="form-control"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mt-4"
                onClick={fetchData}
                disabled={loading}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6 offset-md-3">
          <label htmlFor="request-type" className="form-label">Select Request Type:</label>
          <select
            id="request-type"
            className="form-select"
            value={requestType}
            onChange={handleRequestTypeChange}
          >
            <option value="sent">Sent Requests</option>
            <option value="declined">Declined Requests</option>
            <option value="received">Received Requests</option>
            <option value="accepted">Accepted Requests</option>
          </select>
        </div>
      </div>
      {loading && <div className="text-center">Loading...</div>}
      {error && <div className="alert alert-danger text-center">{error}</div>}
      {!loading && !error && requests.length === 0 && (
        <div className="alert alert-info text-center">No matching requests found.</div>
      )}
      {
      (requests.length ==0 && userData.length!=0) && (<table className="table table-hover table-bordered">
      <thead className="table-dark">
        <tr>
          <th scope="col">Profile Photo</th>
          <th scope="col">First Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
        </tr>
      </thead>
      <tbody>
        {userData.map((request, index) => (
          <tr key={index}>
            <td>
              <img
                src={request.photos?.[0]?.url || 'https://via.placeholder.com/50'}
                alt={request.firstName}
                className="img-thumbnail"
                width="50"
                height="50"
              />
            </td>
            <td>{request.firstName}</td>
            <td>{request.email}</td>
            <td>{request.phone}</td>
          </tr>
        ))}
      </tbody>
    </table>)
      }
      {!loading && !error && requests.length > 0 && (
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <table className="table table-hover table-bordered">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Profile Photo</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={request.photos?.[0]?.url || 'https://via.placeholder.com/50'}
                        alt={request.firstName}
                        className="img-thumbnail"
                        width="50"
                        height="50"
                      />
                    </td>
                    <td>{request.firstName}</td>
                    <td>{request.email}</td>
                    <td>{request.phone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewMatches;
