import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SparkleActivateDeactivate.css';

const apiBaseUrl = 'https://admin.hisathi.com';

const SparkleActivateDeactivate = () => {
  const [sparkles, setSparkles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState('');
  const [searchId, setSearchId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [selectedSparkleId, setSelectedSparkleId] = useState(null);
  const [actionType, setActionType] = useState(null); // "activate" or "deactivate"

  useEffect(() => {
    fetchSparkles();
  }, []);

  const fetchSparkles = async () => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.get(`${apiBaseUrl}/super-admin/sparkle-lists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSparkles(response.data.sparkles || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sparkles:", error);
      setSparkles([]);
      setLoading(false);
    }
  };

  const handleSearchUser = async () => {
    try {
      const token = localStorage.getItem('user_token');
      let response;

      if (email) {
        response = await axios.post(`${apiBaseUrl}/super-admin/search-user-by-email`, {
          email,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (phone) {
        response = await axios.post(`${apiBaseUrl}/super-admin/search-user-by-phone`, {
          phone,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (name) {
        const [firstName, lastName] = name.split(" ");
        response = await axios.post(`${apiBaseUrl}/super-admin/search-user-by-name`, {
          firstName, lastName
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (searchId) {
        response = await axios.post(`${apiBaseUrl}/super-admin/search-user-by-id`, {
          userId: searchId,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        console.error('At least one search criterion is required.');
        return;
      }
      console.log('User found:', response.data);
      setUserId(response.data._id);
    } catch (error) {
      console.error("Error searching user:", error);
    }
  };

  const handleActivateForUser = async () => {
    if (!userId || !selectedSparkleId) return;

    try {
      const token = localStorage.getItem('user_token');
      await axios.post(`${apiBaseUrl}/super-admin/user/sparkle/activate`, {
        _id: userId,
        sparkleId: selectedSparkleId,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setSparkles(prevState =>
        prevState.map(sparkle =>
          sparkle._id === selectedSparkleId ? { ...sparkle, active: true } : sparkle
        )
      );
      setSelectedSparkleId(null);
      setUserId('');
      setSearchId('');
      setEmail('');
      setPhone('');
      setName('');
      setActionType(null);
    } catch (error) {
      console.error("There was an error activating the sparkle for the user!", error);
    }
  };

  const handleDeactivateForUser = async () => {
    if (!userId || !selectedSparkleId) return;

    try {
      const token = localStorage.getItem('user_token');
      await axios.post(`${apiBaseUrl}/super-admin/user/sparkle/deactivate`, {
        _id: userId,
        sparkleId: selectedSparkleId,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSparkles(prevState =>
        prevState.map(sparkle =>
          sparkle._id === selectedSparkleId ? { ...sparkle, active: false } : sparkle
        )
      );
      setSelectedSparkleId(null);
      setUserId('');
      setSearchId('');
      setEmail('');
      setPhone('');
      setName('');
      setActionType(null);
    } catch (error) {
      console.error("There was an error deactivating the sparkle for the user!", error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="sparkle-container">
      <h1>Sparkle Activate/Deactivate</h1>
      {selectedSparkleId && actionType && (
        <div className="search-container">
          <input
            type="text"
            placeholder="Enter User ID"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button onClick={handleSearchUser}>Search</button>
        </div>
      )}
      {userId && (
        <div className="action-button-container">
          <button
            onClick={actionType === 'activate' ? handleActivateForUser : handleDeactivateForUser}
            style={{ width: '120px' }}
          >
            {actionType.charAt(0).toUpperCase() + actionType.slice(1)}
          </button>
        </div>
      )}
      <div className="sparkle-list">
        {sparkles.map(sparkle => (
          <div key={sparkle._id} className="sparkle-card">
            <h2>{sparkle.name}</h2>
            <p>Status: {sparkle.active ? 'Active' : 'Inactive'}</p>
            <button
              className="activate-btn"
              onClick={() => {
                setSelectedSparkleId(sparkle._id);
                setActionType('activate');
              }}
              style={{ width: '120px' }}
            >
              Activate
            </button>
            <button
              className="deactivate-btn"
              onClick={() => {
                setSelectedSparkleId(sparkle._id);
                setActionType('deactivate');
              }}
              style={{ width: '120px' }}
            >
              Deactivate
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SparkleActivateDeactivate;
