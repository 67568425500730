import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilSignOutAlt,
} from "@iconscout/react-unicons";

import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";

import img1 from "../imgs/img1.png";
import img2 from "../imgs/img2.png";
import img3 from "../imgs/img3.png";

export const SidebarData = [
  {
    icon: UilEstate,
    heading: "Dashboard",
  },
  {
    icon: UilClipboardAlt,
    heading: "Orders",
  },
  {
    icon: UilUsersAlt,
    heading: "Customers",
  },
  {
    icon: UilPackage,
    heading: 'Products'
  },
  {
    icon: UilChart,
    heading: 'Analytics'
  },
];

// Analytics Cards Data
export const cardsData = [
  {
    title: "Total Users",
    color: {
      backGround: "#007bff",
      boxShadow: "0px 10px 20px 0px rgba(0, 123, 255, 0.2)",
    },
    barValue: 70,
    value: "10,000",
    png: UilUsersAlt,
    series: [
      {
        name: "Total Users",
        data: [2000, 4000, 6000, 8000, 10000, 12000, 14000],
      },
    ],
  },
  {
    title: "Male Users",
    color: {
      backGround: "#28a745",
      boxShadow: "0px 10px 20px 0px rgba(40, 167, 69, 0.2)",
    },
    barValue: 80,
    value: "6,000",
    png: UilUsersAlt,
    series: [
      {
        name: "Male Users",
        data: [1000, 2000, 3000, 4000, 5000, 6000, 7000],
      },
    ],
  },
  {
    title: "Female Users",
    color: {
      backGround: "#dc3545",
      boxShadow: "0px 10px 20px 0px rgba(220, 53, 69, 0.2)",
    },
    barValue: 60,
    value: "4,000",
    png: UilUsersAlt,
    series: [
      {
        name: "Female Users",
        data: [800, 1600, 2400, 3200, 4000, 4800, 5600],
      },
    ],
  },
  {
    title: "Active Users",
    color: {
      backGround: "#ffc107",
      boxShadow: "0px 10px 20px 0px rgba(255, 193, 7, 0.2)",
    },
    barValue: 90,
    value: "8,000",
    png: UilUsersAlt,
    series: [
      {
        name: "Active Users",
        data: [1600, 3200, 4800, 6400, 8000, 9600, 11200],
      },
    ],
  },
  {
    title: "Inactive Users",
    color: {
      backGround: "#17a2b8",
      boxShadow: "0px 10px 20px 0px rgba(23, 162, 184, 0.2)",
    },
    barValue: 40,
    value: "1,500",
    png: UilUsersAlt,
    series: [
      {
        name: "Inactive Users",
        data: [300, 600, 900, 1200, 1500, 1800, 2100],
      },
    ],
  },
  {
    title: "Banned Users",
    color: {
      backGround: "#6c757d",
      boxShadow: "0px 10px 20px 0px rgba(108, 117, 125, 0.2)",
    },
    barValue: 20,
    value: "500",
    png: UilUsersAlt,
    series: [
      {
        name: "Banned Users",
        data: [100, 200, 300, 400, 500, 600, 700],
      },
    ],
  },
];

// Recent Update Card Data
export const UpdatesData = [
  {
    img: img1,
    name: "Andrew Thomas",
    noti: "has ordered Apple smart watch 2500mh battery.",
    time: "25 seconds ago",
  },
  {
    img: img2,
    name: "James Bond",
    noti: "has received Samsung gadget for charging battery.",
    time: "30 minutes ago",
  },
  {
    img: img3,
    name: "Iron Man",
    noti: "has ordered Apple smart watch, samsung Gear 2500mh battery.",
    time: "2 hours ago",
  },
];
