import React, { useState } from 'react';
import SelectBar from '../SelectBar/Selectbar';

import Table from '../Table/Table';
import './MainDash.css';
// Browser 
const MainDash = () => {
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
  return (
    <div className="MainDash">
      <h1>All User</h1>
      <SelectBar 
        status={status} 
        setStatus={setStatus}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      
      <Table />
    </div>
  );
};

export default MainDash;
