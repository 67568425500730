import React, { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import "./ManageSparkel.css";
import { API_URL } from '../utils';

const ManageSparkel = () => {
  const [sparkels, setSparkels] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    intensity: 0,
    duration: 0,
    price: 0,
    sparkle_count: 0,
    couponCode: "",
    discountPercentage: 0,
    expiryDate: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [currentSparkelId, setCurrentSparkelId] = useState(null);

  useEffect(() => {
    fetchSparkels();
  }, []);

  const fetchSparkels = async () => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.get(`${API_URL}/super-admin/sparkle-lists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSparkels(response.data.sparkles || []);
    } catch (error) {
      console.error("Error fetching sparkels:", error);
      setSparkels([]);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      await updateSparkel();
    } else {
      await createSparkel();
    }
    resetForm();
  };

  const createSparkel = async () => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.post(`${API_URL}/super-admin/sparkles/create`, {
        ...formData,
        expiryDate: formData.expiryDate.replace("-", "/"),
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSparkels();
    } catch (error) {
      console.error("Error creating sparkel:", error);
    }
  };

  const updateSparkel = async () => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.put(`${API_URL}/super-admin/sparkles/update/${currentSparkelId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSparkels();
    } catch (error) {
      console.error("Error updating sparkel:", error);
    }
  };

  const handleEditSparkel = (sparkel) => {
    setFormData({
      name: sparkel.name,
      type: sparkel.type,
      intensity: sparkel.intensity,
      duration: sparkel.duration,
      price: sparkel.price,
      sparkle_count: sparkel.sparkle_count,
      couponCode: sparkel.coupon.code,
      discountPercentage: sparkel.coupon.discountPercentage,
      expiryDate: sparkel.coupon.expiryDate.split("T")[0],
    });
    setEditMode(true);
    setCurrentSparkelId(sparkel._id);
  };

  const handleDeleteSparkel = async (id) => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.delete(`${API_URL}/super-admin/sparkles/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSparkels();
    } catch (error) {
      console.error("Error deleting sparkel:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      type: "",
      intensity: 0,
      duration: 0,
      price: 0,
      sparkle_count: 0,
      couponCode: "",
      discountPercentage: 0,
      expiryDate: "",
    });
    setEditMode(false);
    setCurrentSparkelId(null);
  };

  return (
    <div className="manage-sparkel-container p-4">
      <div className="flex-container">
        <div className="form-container mb-8 bg-white p-4 rounded shadow">
          <h2 className="create-sparkel-text text-2xl mb-4">
            {editMode ? "Edit Sparkel" : "Create Sparkel"}
          </h2>
          <form onSubmit={handleSubmit} className="sparkel-form space-y-4">
            <input
              type="text"
              name="name"
              placeholder="Sparkel Name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="type"
              placeholder="Type"
              value={formData.type}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="number"
              name="intensity"
              placeholder="Intensity"
              value={formData.intensity}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="number"
              name="duration"
              placeholder="Duration (minutes)"
              value={formData.duration}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="number"
              name="sparkle_count"
              placeholder="Sparkle Count"
              value={formData.sparkle_count}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="couponCode"
              placeholder="Coupon Code"
              value={formData.couponCode}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <input
              type="number"
              name="discountPercentage"
              placeholder="Discount Percentage"
              value={formData.discountPercentage}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <input
              type="date"
              name="expiryDate"
              placeholder="Expiry Date"
              value={formData.expiryDate}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <div style={{
              display: "flex",
              justifyContent: "center"
            }}>

            <Button type="submit" variant="contained" color="primary" startIcon={editMode ? <SaveIcon /> : <CreateIcon />}>
              {editMode ? "Update " : "Create "}
            </Button>
            </div>
            {editMode && (
              <Button type="button" onClick={resetForm} variant="contained" color="secondary" startIcon={<CancelIcon />}>
                Cancel
              </Button>
            )}
          </form>
        </div>
        <div className="sparkel-list">
          <h2 className="text-2xl font-bold mb-4">Existing Sparkels</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {sparkels && sparkels.length > 0 ? (
              sparkels.map((sparkel) => (
                <div key={sparkel._id} className="sparkel-item p-4 border border-gray-300 rounded shadow">
                  <p className="sparkel-name">Name: {sparkel.name}</p>
                  <p className="sparkel-type">Type: {sparkel.type}</p>
                  <p className="sparkel-intensity">Intensity: {sparkel.intensity}</p>
                  <p className="sparkel-duration">Duration: {sparkel.duration} minutes</p>
                  <p className="sparkel-price">Price: ${sparkel.price}</p>
                  <p className="sparkel-sparkleCount">Sparkle Count: {sparkel.sparkle_count}</p>
                  <p className="sparkel-couponCode">Coupon Code: {sparkel.coupon.code}</p>
                  <p className="sparkel-discountPercentage">Discount Percentage: {sparkel.coupon.discountPercentage}%</p>
                  <p className="sparkel-expiryDate">Expiry Date: {sparkel.coupon.expiryDate.split("T")[0]}</p>
                  <Stack direction="row" gap={1} className="mt-4">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleEditSparkel(sparkel)}
                      sx={{ width: '80px', backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#388e3c' } }}
                    >
                     
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteSparkel(sparkel._id)}
                      sx={{ width: '80px', backgroundColor: '#f44336', '&:hover': { backgroundColor: '#d32f2f' } }}
                    >
                     
                    </Button>
                  </Stack>
                </div>
              ))
            ) : (
              <p>No sparkels found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSparkel;
