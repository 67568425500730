import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import Logo from "../imgs/logo.png";
import { UilSignOutAlt, UilHome } from "@iconscout/react-unicons";
import { motion } from "framer-motion";

const Sidebar = ({ handleLogout }) => {
  const Catalog = [
    {
      name: "User Management",
      children: ["All Users", "User Verification Requests","Default Photo"],
    },
   
    {
      name: "Matches Management",
      children: ["View Matches", "Chat History"],
    },
    {
      name: "Subscription Management",
      children: ["Manage Plans", "Activate/Deactivate Plan"],
    },
    {
      name: "Sparkel Management",
      children: ["Create Sparkel", "Activate/Deactivate Sparkles"],
    },
    {
      name: "Query Management",
      children: [ "Queries Data"],
    },
  ];

  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const sidebarVariants = {
    true: {
      left: '0',
    },
    false: {
      left: '-60%',
    },
  };

  return (
    <>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        <div className="logo">
          <img src={Logo} alt="logo" />
          <span>
            Hi<span>sa</span>thi
          </span>
        </div>

        <div className="menu">
          <div className="menuItem" onClick={() => navigate("/dashboard")}>
            <UilHome /> <span>Home</span>
          </div>
          {Catalog.map((item, index) => (
            <div
              className={selected === index ? "menuItem active" : "menuItem"}
              key={index}
              onClick={() => setSelected(selected === index ? null : index)}
            >
              <span>{item.name}</span>
              {selected === index && (
                <ul>
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex}>
                      <Link
                        to={
                          child === "View Matches"
                            ? "/view-matches"
                            : child === "Manage Plans"
                            ? "/manage-plans"
                            : child === "All Users"
                            ? "/all-users"
                            : child === "User Verification Requests"
                            ? "/user-verification-requests"
                            : child === "Default Photo"
                            ? "/default-photo"
                           
                            : child === "Create Sparkel"
                            ? "/manage-sparkel"
                            : child === "Chat History"
                            ? "/chat-history"
                            : child === "Activate/Deactivate Sparkles"
                            ? "/sparkle-activate-deactivate"
                            : child === "Activate/Deactivate Plan"
                            ? "/plan-activate-deactivate"
                            : child === "Queries Data"
                            ? "/querry"
                            : ""
                        }
                      >
                        {child}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className="menuItem" onClick={handleLogout}>
            <UilSignOutAlt />
            <span>Logout</span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
