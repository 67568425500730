import React, { useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import MainDash from "./components/MainDash/MainDash";
import RightSide from "./components/RightSide/RightSide";
import Sidebar from "./components/Sidebar";
import LoginScreen from "./components/LoginScreen";
import ManagePlans from "./components/ManagePlans";
import AllUsersPage from "./components/AllUsersPage";
import ManageSparkel from "./components/ManageSparkle";
import VerificationComponent from "./components/VerificationComponent"; 
import ViewMatches from './components/ViewMatches';
import ChatHistory from './components/ChatHistory'; 
import SetDefaultPhotoModal from "./components/Default-photo";
import SparkleActivateDeactivate from './components/SparkleActivateDeactivate';
import PlanActivateDeactivate from './components/PlanActivateDeactivate';
import HomePage from "./components/HomePage";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import QueryMessages from "./components/QuerryMessages";

function App() {
  const [login, setLogin] = useState(localStorage.getItem("user_token")?.length ?? false);
  const navigate = useNavigate();

  const handleLogin = () => {
    setLogin(true);
    // localStorage.setItem("user_token", "dummy_token"); // Set a dummy token or replace with real token
    navigate("/dashboard");
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen setLogin={handleLogin} />} />
      <Route
        path="/dashboard"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar
                  handleLogout={() => {
                    setLogin(false);
                    localStorage.removeItem("user_token");
                  }}
                />
                <HomePage />
                <RightSide />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/manage-plans"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <ManagePlans />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      
      <Route
        path="/all-users"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <AllUsersPage />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/manage-sparkel"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <ManageSparkel />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/user-verification-requests"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <VerificationComponent />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
       <Route
        path="/default-photo"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                < SetDefaultPhotoModal/>
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/view-matches"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <ViewMatches />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/chat-history"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <ChatHistory />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/sparkle-activate-deactivate"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <SparkleActivateDeactivate />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/plan-activate-deactivate"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <PlanActivateDeactivate />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
       <Route
        path="/querry"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <QueryMessages />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="*" element={<Navigate to={login ? "/dashboard" : "/login"} />} />
    </Routes>
  );
}

export default App;
