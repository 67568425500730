import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils";

function LoginScreen({ setLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/super-admin/super-admin-login`,
        {
          email,
          password,
        }
      );

      if (response.status === 200) {
        localStorage.setItem("user_token", response.data.token);
        setLogin();
        navigate("/dashboard"); // Navigate to dashboard on successful login
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh", width: "100%" }}>
      {/* Black Side */}
      <div 
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "50%",
          backgroundColor: "#000",
          color: "#fff",
          position: "relative",
          overflow: "hidden",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <div 
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontSize: "48px",
            fontWeight: "700",
            padding: "20px",
            border: "2px solid #fff",
            borderRadius: "12px",
            boxShadow: "0 6px 12px rgba(255, 255, 255, 0.4)",
            textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
            background: "rgba(0, 0, 0, 0.5)",
            position: "relative",
            zIndex: "1"
          }}
        >
          Hisathi
        </div>
      </div>

      {/* White Side */}
      <div 
        style={{
          width: "50%",
          backgroundColor: "#f4f4f4",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div style={{ width: "100%", maxWidth: "400px", backgroundColor: "#fff", padding: "30px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <h2 style={{ marginBottom: "20px", color: "#333", fontFamily: "'Roboto', sans-serif", fontWeight: "500" }}>Login</h2>
          <div style={{ marginBottom: "20px", position: "relative" }}>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              name="email"
              id="email"
              style={{
                width: "100%",
                padding: "15px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                fontSize: "16px",
                outline: "none",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s",
                backgroundColor: "#f9f9f9"
              }}
            />
            <label
              htmlFor="email"
              style={{
                position: "absolute",
                top: "-10px",
                left: "10px",
                background: "#f4f4f4",
                padding: "0 5px",
                fontSize: "12px",
                color: "#888",
                transition: "top 0.3s, font-size 0.3s"
              }}
            >
              Email
            </label>
          </div>
          <div style={{ marginBottom: "20px", position: "relative" }}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              name="password"
              id="password"
              style={{
                width: "100%",
                padding: "15px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                fontSize: "16px",
                outline: "none",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s",
                backgroundColor: "#f9f9f9"
              }}
            />
            <label
              htmlFor="password"
              style={{
                position: "absolute",
                top: "-10px",
                left: "10px",
                background: "#f4f4f4",
                padding: "0 5px",
                fontSize: "12px",
                color: "#888",
                transition: "top 0.3s, font-size 0.3s"
              }}
            >
              Password
            </label>
          </div>
          {error && <div style={{ color: "red", marginBottom: "20px", fontSize: "14px" }}>{error}</div>}
          <button 
            onClick={handleLogin} 
            style={{
              padding: "15px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "16px",
              transition: "background-color 0.3s, transform 0.3s",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;