import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Input, Card, CardContent, CircularProgress, IconButton } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

// Base API URL
const apiBaseUrl = 'https://admin.hisathi.com';

// Function to upload the default photo
async function uploadDefaultPhoto(file) {
    const apiUrl = `${apiBaseUrl}/super-admin/upload-default-photo`;
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(apiUrl, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
        });

        // Return JSON response
        return response.data;
    } catch (error) {
        console.error('Error uploading default photo:', error);
        throw error;
    }
}

const InputStyled = styled(Input)({
    display: 'none',
});

const DefaultPhotoUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [loading, setLoading] = useState(false);

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setUploadStatus(''); // Clear previous status
        }
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadStatus('Please select a file.');
            return;
        }

        setUploadStatus('Uploading...');
        setLoading(true);
        try {
            const result = await uploadDefaultPhoto(selectedFile);
            setUploadStatus('Upload successful!');
            console.log('Upload result:', result);
        } catch (error) {
            setUploadStatus('Upload failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={styles.outerContainer}>
            <Box sx={styles.container}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Upload Default Photo
                </Typography>
                <Card sx={styles.card}>
                    <CardContent sx={styles.cardContent}>
                        <label htmlFor="upload-photo">
                            <InputStyled 
                                accept="image/*" 
                                id="upload-photo" 
                                type="file" 
                                onChange={handleFileChange} 
                            />
                            <IconButton 
                                color="primary" 
                                aria-label="upload picture" 
                                component="span"
                            >
                                <CloudUploadIcon sx={{ fontSize: 40 }} />
                            </IconButton>
                        </label>
                        {selectedFile && (
                            <Typography variant="body1" sx={styles.fileName}>
                                {selectedFile.name}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpload}
                            sx={styles.uploadButton}
                            disabled={!selectedFile || loading}
                            startIcon={loading ? <CircularProgress size={24} /> : <CloudUploadIcon />}
                        >
                            Upload 
                        </Button>
                        <Typography
                            variant="body2"
                            sx={{
                                ...styles.statusText,
                                color: uploadStatus.includes('failed') ? 'red' : 'green',
                            }}
                        >
                            {uploadStatus}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

// Basic styles for the component
const styles = {
    outerContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'linear-gradient(to right, #f5f5f5, #e0e0e0)',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
    },
    card: {
        width: '100%',
        maxWidth: 400,
        padding: '20px',
        borderRadius: '10px',
        background: '#fff',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fileName: {
        marginTop: '10px',
        marginBottom: '10px',
        wordBreak: 'break-all',
        textAlign: 'center',
    },
    uploadButton: {
        marginTop: '10px',
        width: '50%',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    statusText: {
        marginTop: '10px',
        fontSize: '16px',
        textAlign: 'center',
    },
};

export default DefaultPhotoUploader;
