import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './VerificationComponent.css';

const apiBaseUrl = 'https://admin.hisathi.com';

const VerificationComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [photos, setPhotos] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);
  const [defaultPhotoId, setDefaultPhotoId] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showVerificationSections, setShowVerificationSections] = useState(false);
  const [userId, setUserId] = useState(null); // Add this state

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (searchQuery || searchName || searchEmail) {
      fetchUserData();
    }
  }, [searchQuery, searchName, searchEmail]);

  const fetchUserData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiBaseUrl}/super-admin/all-users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_token')}`,
        },
      });

      const user = response.data.find(user =>
        user.userId === searchQuery || 
        `${user.firstName} ${user.lastName}` === searchName || 
        user.email === searchEmail
      );

      if (!user) {
        setError('User not found');
        setShowVerificationSections(false);
        return;
      }

      setUserId(user.userId); // Set the userId for later use

      const userPhotos = user.photos.map(photo => ({
        id: photo._id,
        url: photo.url,
        status: photo.verified ? 'verified' : 'pending'
      }));
      setPhotos(userPhotos);
      setDefaultPhotoId(user.defaultPhotoId || null);

      const userDocuments = user.documents.map(doc => ({
        id: doc._id,
        name: doc.filename,
        url: doc.url,
        type: doc.type,
        status: doc.verified ? 'verified' : 'pending'
      }));
      setDocuments(userDocuments);
      setShowVerificationSections(true); // Set to true if user data is found
    } catch (error) {
      if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
        setError('Network error. Please check your internet connection.');
      } else if (error.message.includes('net::ERR_NAME_NOT_RESOLVED')) {
        setError('DNS resolution error. Please check the URL or DNS settings.');
      } else {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data.');
      }
      setShowVerificationSections(false);
    } finally {
      setLoading(false);
    }
  };

  const verifyPhoto = async (photoId) => {
    if (!userId) return; // Ensure userId is available before proceeding
    setLoading(true);
    setError(null);
    console.log('Verifying photo with userId:', userId, 'and photoId:', photoId);
    try {
      const response = await axios.put(
        `${apiBaseUrl}/super-admin/verify-photo/${userId}/${photoId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        }
      );
  
      console.log('Response:', response);
  
      if (!response.data.message) {
        throw new Error('Verification failed');
      }
  
      setVerificationStatus(`Photo ${photoId} verified successfully.`);
      setPhotos(prevPhotos =>
        prevPhotos.map(photo =>
          photo.id === photoId ? { ...photo, status: 'verified' } : photo
        )
      );
    } catch (error) {
      console.log('Error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const verifyDocument = async (documentId) => {
    if (!userId) return; // Ensure userId is available before proceeding
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/super-admin/verify-document/${userId}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        }
      );

      if (response.data.message !== "Document verified successfully") {
        throw new Error('Verification failed');
      }

      setVerificationStatus(`Document ${documentId} verified successfully.`);
      setDocuments(prevDocuments =>
        prevDocuments.map(doc =>
          doc.id === documentId ? { ...doc, status: 'verified', verified: true } : doc
        )
      );
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getDocumentIcon = (type) => 'fas fa-file-alt';

  return (
    <div className="container">
      <h1>Verification Component</h1>
      <div className="search-bar">
        <div className="search-fields">
          <div>
            <label htmlFor="searchQuery">Search by User ID:</label>
            <input
              type="text"
              id="searchQuery"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="searchName">Search by Name:</label>
            <input
              type="text"
              id="searchName"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="searchEmail">Search by Email:</label>
            <input
              type="text"
              id="searchEmail"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
          </div>
          <button  style={{
                        width: '100px',
                        display: 'block',
                       
                        textAlign: 'center'
                      }} onClick={fetchUserData} disabled={loading} >
            Search
          </button>
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}

      {showVerificationSections && (
        <>
          <div className="verification-section">
            <h2>Verify Photos</h2>
            <div className="photos-container">
              {photos.map(photo => (
                <div key={photo.id} className="photo-item">
                  <img src={photo.url} alt={`Photo ${photo.id}`} />
                  <p>Status: {photo.status}</p>
                  {photo.status !== 'verified' && (
                    <button
                      style={{
                        width: '100px',
                        display: 'block',
                        margin: '10px auto',
                        textAlign: 'center'
                      }}
                      onClick={() => verifyPhoto(photo.id)}
                    >
                      Verify
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="verification-section">
            <h2>Verify Documents</h2>
            <div className="documents-container">
              {documents.map(doc => (
                <div key={doc.id} className="document-item" style={{ marginBottom: '20px' }}>
                  <i className={getDocumentIcon(doc.type)}></i>
                  <p style={{ margin: '5px 0' }}>{doc.name}</p>
                  <p style={{ margin: '5px 0', fontWeight: 'bold' ,marginLeft:'30px'}}>{doc.status}</p>
                  {doc.status !== 'verified' && (
                    <button
                      style={{
                        width: '100px',
                        display: 'block',
                        margin: '10px auto',
                        textAlign: 'center'
                      }}
                      onClick={() => verifyDocument(doc.id)}
                    >
                      Verify
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {verificationStatus && <p>{verificationStatus}</p>}
    </div>
  );
};

export default VerificationComponent;
