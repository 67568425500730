import React, { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import "./ManagePlans.css"; // Import the CSS file for styling

export const API_URL = "https://admin.hisathi.com";

const ManagePlans = () => {
  const [plans, setPlans] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    duration: "",
    price: "",
    couponCode: "",
    discountPercentage: "",
    expiryDate: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(null);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.get(`${API_URL}/super-admin/plans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPlans(response.data.plans);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      await updatePlan();
    } else {
      await createPlan();
    }
    resetForm();
  };

  const createPlan = async () => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.post(`${API_URL}/super-admin/plans/create`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchPlans();
    } catch (error) {
      console.error("Error creating plan:", error);
    }
  };

  const updatePlan = async () => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.put(`${API_URL}/super-admin/plans/update/${currentPlanId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchPlans();
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  const handleEditPlan = (plan) => {
    setFormData({
      name: plan.name,
      duration: plan.duration,
      price: plan.price,
      // Uncomment the following lines if you decide to include coupon information
      // couponCode: plan.coupon?.code || "",
      // discountPercentage: plan.coupon?.discountPercentage || "",
      // expiryDate: plan.coupon?.expiryDate?.split("T")[0] || "",
    });
    setEditMode(true);
    setCurrentPlanId(plan._id);
  };

  const handleDeletePlan = async (id) => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.delete(`${API_URL}/super-admin/plans/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchPlans();
    } catch (error) {
      console.error("Error deleting plan:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      duration: "",
      price: "",
      couponCode: "",
      discountPercentage: "",
      expiryDate: "",
    });
    setEditMode(false);
    setCurrentPlanId(null);
  };

  return (
    <div className="manage-plans-container p-4">
      <div className="flex-container">
        <div className="form-container mb-8">
          <h2 className="create-plan-text text-2xl mb-4">{editMode ? "Edit Plan" : "Create Plan"}</h2>
          <form onSubmit={handleSubmit} className="plan-form space-y-4">
            <input
              type="text"
              name="name"
              placeholder="Plan Name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="number"
              name="duration"
              placeholder="Duration (days)"
              value={formData.duration}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="couponCode"
              placeholder="Coupon Code"
              value={formData.couponCode}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <input
              type="number"
              name="discountPercentage"
              placeholder="Discount Percentage"
              value={formData.discountPercentage}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <input
              type="date"
              name="expiryDate"
              placeholder="Expiry Date"
              value={formData.expiryDate}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">
              {editMode ? "Update " : "Create "}
            </button>
            {editMode && (
              <button type="button" onClick={resetForm} className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 ml-4">
                Cancel
              </button>
            )}
          </form>
        </div>
        <div className="plan-list">
          <h2 className="text-2xl font-bold mb-4"></h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {plans.map((plan) => (
              <div key={plan._id} className="plan-item p-4 border border-gray-300 rounded shadow">
                <p className="plan-name">Name: {plan.name}</p>
                <p className="plan-duration">Duration: {plan.duration} days</p>
                <p className="plan-price">Price: ${plan.price}</p>
                {/* Uncomment the following lines if you decide to include coupon information */}
                {/* <p>Coupon: {plan.coupon?.code}</p> */}
                {/* <p>Discount: {plan.coupon?.discountPercentage}%</p> */}
                {/* <p>Expires on: {new Date(plan.coupon?.expiryDate).toLocaleDateString()}</p> */}
                <Stack direction="row" gap={1} className="mt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => handleEditPlan(plan)}
                    sx={{ width: '80px', backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#388e3c' } }}
                  >
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeletePlan(plan._id)}
                    sx={{ width: '80px', backgroundColor: '#f44336', '&:hover': { backgroundColor: '#d32f2f' } }}
                  >
                  </Button>
                </Stack>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePlans;
